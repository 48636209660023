import React from 'react';
import { Grid, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import theme from '../../theme';



const Pricing = () => {
    return (
        <div>

            <Navbar />
            <Grid container justifyContent={'center'} marginTop={"20px"} color={theme.palette.primary.main} spacing={2}>
                <Grid item xs={12} md={9} sx={{ textAlign: 'center' }}>
                    <Typography variant='h4' gutterBottom>
                        Pricing Plans
                    </Typography>
                    <Typography variant='h6' sx={{ fontSize: { xs: 18, md: 20 } }}>
                        Choose the plan that fits your needs
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} marginTop={"40px"} marginBottom={"130px"} spacing={4} sx={{ px: { xs: 2, sm: 4, md: 6 } }}>
                {[
                    { title: "Pricing", price: "Rs. 35/paper" },
                ].map((plan, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} sx={{ mb: { xs: 4, sm: 0 } }}>
                        <div style={{ border: `1px solid ${theme.palette.primary.main}`, borderRadius: '8px', padding: '20px', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='h5' gutterBottom color={theme.palette.primary.main}>
                                {plan.title}
                            </Typography>
                            <Typography variant='h6' gutterBottom>
                                {plan.price}
                            </Typography>
                            <Typography gutterBottom>
                                {plan.students}
                            </Typography>
                            {/* <ul style={{ paddingLeft: '20px', marginBottom: '20px', flex: 1 }}>
                                <li>Paper correction for 1 year.</li>
                                <li>Access to admin dashboard to track your institute.</li>
                                <li>Access to teacher dashboard to track students results and paper correction.</li>
                                <li>Access to student dashboard so students can get personalized feedback and see their results instantly.</li
                            </ul> */}
                            <List sx={{
                                padding: 0,
                                marginBottom: 2,
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                                '& .MuiListItem-root': {
                                    padding: '8px 0',
                                    justifyContent: 'center',
                                }
                            }}>
                                {[
                                    { text: 'Paper correction for 1 year.', icon: 'check' },
                                    { text: 'Access to admin dashboard to track your institute.', icon: 'admin' },
                                    { text: 'Access to teacher dashboard to track students results and paper correction.', icon: 'teacher' },
                                    { text: 'Access to student dashboard so students can get personalized feedback and see their results instantly.', icon: 'student' }
                                ].map((item, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            // alignItems: 'center',
                                            transition: 'transform 0.2s ease',
                                            '&:hover': {
                                                transform: 'translateX(5px)'
                                            }
                                        }}
                                    >
                                        <ListItemIcon sx={{ minWidth: 36 }}>
                                            <CheckCircleOutline
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item.text}
                                            primaryTypographyProps={{
                                                fontSize: '0.95rem',
                                                fontWeight: 500,
                                                color: 'text.secondary'
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            <Button variant="contained" sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main }} fullWidth>
                                Pay Now
                            </Button>
                        </div>
                    </Grid>
                ))}
            </Grid>
            <Footer />
        </div >
    )
}

export default Pricing
